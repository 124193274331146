import "./CSS/NotFound.css"

const NotFound =()=>{
    return(
        <div className="container-fluid not-found-container">
           <div className="inner-container">
               <img src={require("../resources/notFound.png")} style={{height:'200px',width:'200px'}} alt={"NotFound"}/>
               <p>Not Found</p>
           </div>
        </div>
    )
}

export default NotFound;