import {Grid} from "@mui/material";


const HomeDescription=()=>{
    return(
        <div className={"container"}>
            <Grid container>
                <Grid item >
                    <h3 className={"text-center"}>Welcome to ToolsMasterOnline.com</h3>
                    <h1>What is Tools Master</h1>
                    <p>Tools Master is an ultimate destination for a suite of top-notch social media video downloader tools.
                        Elevate your online experience with our specialized social media video downloaders, which supports popular
                        platforms including Facebook, Twitter, Instagram, and YouTube. You can use these tools at no-cost.
                        You can Unlock the ability to save and enjoy your favorite content offline with our dedicated Facebook video downloader,
                        designed to easily save videos from your Facebook feed.
                        Dive into the world of Twitter with our intuitive Twitter video downloader, enabling you to download and
                        share captivating moments directly from Twitter.
                        Immerse yourself in the visual storytelling of Instagram by utilizing our Instagram video downloader –
                        the perfect companion for saving those memorable stories and videos.
                        Plus, take control of your video library with our YouTube video downloader, ensuring you have easy access to your preferred content anytime, anywhere.
                    </p>
                    <h1>Why Tools Master</h1>
                    <ul>
                        <li>
                            Comprehensive Platform Support: Our range of video downloaders covers the most popular social media platforms, offering a one-stop solution for all your downloading needs.
                        </li>
                        <li>Seamless User Experience: Experience a user-friendly interface that simplifies the downloading process, making it accessible to all users, regardless of technical expertise.
                        </li>
                        <li>
                            High-Quality Downloads: Enjoy your saved videos in pristine quality, thanks to our commitment to delivering superior resolution downloads.
                        </li>
                        <li>
                            Regular Updates: Stay ahead of the curve with our dedication to keeping our tools up-to-date, ensuring compatibility with the latest changes on social media platforms.
                        </li>
                    </ul>
                </Grid>
            </Grid>
        </div>
    )
}

export default HomeDescription;