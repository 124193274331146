import * as React from 'react';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import Button from '@mui/material/Button';
import HandymanIcon from '@mui/icons-material/Handyman';
import {KeyboardArrowDown} from "@mui/icons-material";



const Header = () => {
    return (
        <AppBar position="static">
            <Container maxWidth="xl">
                <Toolbar >
                    <HandymanIcon sx={{ display: { xs: 'none', md: 'flex' }, mr: 1 }} />
                    <Typography
                        variant="h6"
                        noWrap
                        component="a"
                        href="/"
                        sx={{
                            mr: 2,
                            display: { xs: 'none', md: 'flex' },
                            fontFamily: 'monospace',
                            fontWeight: 700,
                            letterSpacing: '.3rem',
                            color: 'inherit',
                            textDecoration: 'none',
                        }}
                    >
                        ToolsMaster
                    </Typography>
                    <Box sx={{flexGrow:1}}>
                        <HandymanIcon sx={{ display: { xs: 'flex', md: 'none' } }} />
                        <Typography
                            variant="h5"
                            noWrap
                            component="a"
                            href="#app-bar-with-responsive-menu"
                            sx={{
                                mr: 2,
                                display: { xs: 'flex', md: 'none' },
                                fontFamily: 'monospace',
                                fontWeight: 600,
                                letterSpacing: '.3rem',
                                color: 'inherit',
                                textDecoration: 'none',
                            }}
                        >
                            ToolsMaster
                        </Typography>
                    </Box>


                    <Box sx={{ flexGrow: 0 }}>
                        <Button
                            id="demo-customized-button"
                            endIcon={<KeyboardArrowDown />}>Video Downloaders
                        </Button>
                    </Box>
                </Toolbar>
            </Container>
        </AppBar>
    )
}

export default Header;
