import './App.css';
import Header from "./common-components/Header";
import {Route, Routes} from "react-router-dom";
import {HelmetProvider} from "react-helmet-async";
import Home from "./pages/Home";
import FacebookDownloader from "./pages/FacebookDownloader";
import TwitterDownloader from "./pages/TwitterDownloader";
import InstagramDownloader from "./pages/InstagramDownloader";
import YoutubeDownloader from "./pages/YoutubeDownloader";
import PrivacyPolicy from "./pages/PrivacyPolicy";
import NotFound from "./pages/NotFound";
import Footer from "./common-components/Footer";

function App() {
  return (
      <HelmetProvider>
          <Header />
          <Routes >
              <Route index element={<Home/>} />
              <Route path="/facebookdownloader" element={<FacebookDownloader/>} />
              <Route path="/twitterdownloader" element={<TwitterDownloader/>} />
              <Route path="/instagramdownloader" element={<InstagramDownloader/>} />
              <Route path="/youtubedownloader" element={<YoutubeDownloader/>} />
              <Route path="/privacypolicy" element={<PrivacyPolicy/>} />
              <Route path="*" element={<NotFound/>} />
          </Routes>
          <Footer/>
      </HelmetProvider>
  );
}

export default App;
