 import CardComponent from "./subcomponents/CardComponent";
import {db} from "../../firebase";
import {useEffect, useState} from "react";
import {collection, getDocs} from "firebase/firestore";
import "./CSS/VideoTools.css";
 import {CircularProgress, Grid} from "@mui/material";

const VideoTools = () => {
    const [videoTools, setVideoTools] = useState([]);
    const [loading, setLoading] = useState(true);
    const getVideoTools = ()=>{
         getDocs(collection(db,'videoDownloaders'))
            .then(snapshot=>{
                let newData = [];
                snapshot.docs.forEach((doc)=>{
                    newData.push({...doc.data(),id:doc.id});
                    setVideoTools([...newData]);
                    setLoading(false);
                })
            })
            .catch(err=>{
                console.log(err);
            })
    }

    useEffect(()=>{
        getVideoTools();
    },[])
  return(
      <div className="container">
            <h1 className={"text-center"}>Free Social Media Video Downloaders - Download videos from Facebook, Twitter, Instagram and YouTube</h1>
          <div className="d-flex cardContainer">
              <Grid container >
                  {loading?<CircularProgress className="circular-progress" />: videoTools.length>0 && videoTools.map(tool=>{
                      return(
                          <Grid style={{display:'flex',justifyContent:'center'}}  key={tool.id} item xs={12} sm={6} md={6} lg={3}>
                              <CardComponent className="d-flex" data = {tool} />
                          </Grid>
                      )
                  })}
              </Grid>
          </div>
      </div>
  )
}

export default VideoTools;
