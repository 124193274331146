import HandymanIcon from "@mui/icons-material/Handyman";
import Typography from "@mui/material/Typography";
import * as React from "react";

const Logo = () => {
    return (
        <>
            <HandymanIcon sx={{ display: { xs: 'flex', md: 'flex' }, mr: 1 }} />
            <Typography
                variant="h6"
                noWrap
                component="a"
                href="/"
                sx={{
                    mr: 2,
                    display: { xs: 'flex', md: 'flex' },
                    fontFamily: 'monospace',
                    fontWeight: 700,
                    letterSpacing: '.3rem',
                    color: 'inherit',
                    textDecoration: 'none',
                }}
            >
                ToolsMaster
            </Typography></>
    )
}

export default Logo;
