
const PrivacyPolicy =()=>{
    return(
        <div className={"container"}>
            <h1 className={"text-center"}>Privacy Policy</h1>
            <h4 className={"text-center"}>Last Updated - 18/11/2023</h4>
            <p>
                Thank you for using Tools Master. This page informs you our policies regarding the collection,
                use, and disclosure of personal information when you use our services.
                We do not collect any personal information from our users. The only information we may collect is
                non-personal information such as browser type, language preference, referring site, and the date and
                time of each visitor request. This information is used solely for the purpose of better understanding how our visitors use our website.
            </p>
            <h4>
                Information Collection And Use
            </h4>
            <p>
                We do not collect any personal information from our users. Any information collected is used for
                analytical purposes and is not shared with third parties.
            </p>
            <h4>
                Log Data
            </h4>
            <p>
                Like many sites, we collect information that your browser sends whenever you visit our site ("Log Data").
                This Log Data may include information such as your computer's Internet Protocol ("IP") address, browser type,
                browser version, the pages of our site that you visit, the time and date of your visit, the time spent on those pages, and other statistics.
            </p>
            <h4>
                Cookies
            </h4>
            <p>
                We do not use cookies on our website.
            </p>
            <h4>
                Security
            </h4>
            <p>
                The security of your Personal Information is important to us, but remember that no method of transmission
                over the Internet, or method of electronic storage, is 100% secure.
                While we strive to use commercially acceptable means to protect your Personal Information, we cannot guarantee its absolute security.
            </p>
            <h4>
                Links To Other Sites
            </h4>
            <p>
                Our website may contain links to other sites that are not operated by us.
                If you click on a third-party link, you will be directed to that third party's site.
                We strongly advise you to review the Privacy Policy of every site you visit.
            </p>
            <h4>
                Copyrights
            </h4>
            <p>
                Users of ToolsMaster are required to respect the copyrights of the original content creators.
                The tools provided on this platform are intended for personal use only, and users are prohibited from
                using these tools to infringe upon the copyrights of others.
            </p>
            <h4>
                Changes To This Privacy Policy
            </h4>
            <p>
                We may update our Privacy Policy from time to time. We will notify you of any changes by posting the new Privacy Policy on this page.
            </p>
        </div>
    )
}

export default PrivacyPolicy;