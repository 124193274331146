import VideoTools from "./components/VideoTools";
import {Helmet} from "react-helmet-async";
import HomeDescription from "./components/HomeDescription";

const Home = () => {

    return (
        <div className="container">

            <Helmet>
                <html lang="en" />
                <title>Tools Master</title>
                <meta name="description" content="Tools Master, Tools Master is a free platform with multiple online tools. You can download facebook videos, twitter videos, Instagram videos, Youtube videos for free, twitter, facebook, instagram, youtube, videos, free tools, video downloader" />
                <meta name="keywords" content="Facebook Video Downloader, Download videos from facebook, Free video Downloader, Instagram video Downloader, Twitter video Downloader, Free, Youtube Video downloader, Download videos from Twitter, Download videos from Instagram, Download videos from Youtube" />
            </Helmet>
            <h1 className="h1 text-center">Welcome to Tools Master - The Free Online Tools Platform</h1>
            <div style={{marginTop:"30px"}}>
                <VideoTools/>
            </div>
            <HomeDescription/>
        </div>
    )
}

export default Home;
