import {Alert, CircularProgress, Link, Snackbar, TextField} from "@mui/material";
import Button from "@mui/material/Button";
import {useState} from "react";
import axios from "axios";
import {Helmet} from "react-helmet-async";


const FacebookDownloader = () => {
    const [url, setUrl] = useState('');
    const [loading, setLoading] = useState(false);
    const [links, setLinks] = useState([]);
    const [error, setError] = useState(false);

    const download = async () => {
        setLoading(true)
        const options = {
            method: 'GET',
            url: 'https://facebook-reel-and-video-downloader.p.rapidapi.com/app/main.php',
            params: {
                url: url
            },
            headers: {
                'X-RapidAPI-Key': 'e4ec10927emsh6bfc7f528f5b208p1f8db3jsn19a2f2c51ead',
                'X-RapidAPI-Host': 'facebook-reel-and-video-downloader.p.rapidapi.com'
            }
        };


        try {
            const response = await axios.request(options);
            if (response.status===200){
                setLinks(response.data.links);
                setLoading(false);
                setUrl("");
                setError(false);
            }else{
                setLinks([]);
                setUrl("");
                setLoading(false);
                setError(true);
            }

        } catch (error) {
            setLinks([]);
            setUrl("");
            setLoading(false);
            setError(true)
        }
    }

    const handleClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }

        setError(false);
    };
    return (
        <div  className="container">
            <Helmet>
                <html lang="en" />
                <title>Facebook Video Downloader</title>
                <meta name="description" content="Tools Master Facebook video downloader allows you to download facebook videos from facebook  for free without any watermark" />
                <meta name="keywords" content="Facebook Video Downloader, Download videos from facebook, Free video Downloader" />
            </Helmet>
            <div className="header-container">
                <div>
                    <h1>Facebook Video Downloader</h1>
                    <p className="text-center">Download Facebook videos for Free</p>
                </div>
            </div>

            <div className="form-container">
                <TextField value={url} onChange={(e)=>setUrl(e.target.value)} className="text-field" id="url" label="Facebook Video Url" placeholder={"Paste your Facebook video Link"} variant="outlined" required/>
                <Button onClick={download} className="download-button" variant={"contained"}>Download</Button>
            </div>

            <div className="download-container">
                <div>
                    {loading?<CircularProgress/>:links &&<div>
                        <h2>Download Links</h2>
                        <div className="links-container">
                            <div className="download-link-container">
                                <Link className="download-link btn btn-primary" style={{textDecoration: 'none',color:'white',width:'100%'}} target="_blank"   href={links['Download High Quality']}>Download in High Quality</Link>
                            </div>
                            <div className="download-link-container">
                                <Link className="download-link btn btn-primary" style={{textDecoration: 'none',color:'white',width:'100%'}} target="_blank"   href={links['Download Low Quality']}>Download in Low Quality</Link>
                            </div>
                        </div>
                    </div>}
                </div>

                <Snackbar anchorOrigin={{  vertical: 'top', horizontal: 'center' }} open={error} autoHideDuration={6000} onClose={handleClose}>
                    <Alert onClose={handleClose} severity="error" sx={{ width: '100%' }}>
                        An Error occurred. Please check your Url and try again.
                    </Alert>
                </Snackbar>
            </div>

            <div className={"container"}>
                <h3 className={"text-center"}>Welcome to ToolsMasterOnline.com - Free Facebook Video Downloader</h3>
                <h1>What is Tools Master - Free Facebook Video Downloader</h1>
                <ul>
                    <li>
                        Totally Free and Easy to use: Enjoy the freedom of downloading Facebook videos without any cost.
                        Our user-friendly and easy to use downloader ensures a seamless experience, making video downloads hassle free for users.Also we let users to download the video in multiple formats
                    </li>
                    <li>
                        Quick Downloads: Our Facebook Video Downloader provides quick downloads, so you can access your favorite content in no time.
                    </li>
                    <li>
                        Multiple Formats: Our Facebook video downloader allows users to download videos in multiple formats including low and high resolutions.
                    </li>
                    <li>
                        High-Quality Resolution:Our downloader ensures that the quality of your saved Facebook videos remain top-notch and also we don't add any watermark to the video.
                    </li>
                </ul>
                <h1>How to Use ToolsMaster Facebook Video Downloader </h1>
                <ol>
                    <li>
                        Copy the Facebook video Link: Find the Facebook video you want to download and copy the link of the video.
                    </li>
                    <li>
                        Paste and Download: Paste the link into our Facebook Video Downloader and hit the download button. You will get some links to download the video in different formats
                    </li>
                </ol>
                <h1>
                    Frequently Asked Questions:
                </h1>
                <h5>
                    Q: Is it really free?
                </h5>
                <p>
                    A: Absolutely! ToolsMaster Facebook Video Downloader is completely free to use. No hidden charges or subscriptions.
                </p>
                <h5>
                    Q: Can I download videos from private accounts?
                </h5>
                <p>
                    A: Our downloader supports public videos. For private accounts, ensure the Facebook's privacy settings allow for public viewing.
                </p>
                <h5>
                    Q: s it legal to download Facebook videos?
                </h5>
                <p>
                    A: Yes, it's legal to download videos for personal use. However, be sure to respect copyright and intellectual property rights of the original content creator.
                </p>
            </div>
        </div>
    )
}

export default FacebookDownloader;