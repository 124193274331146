// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import {getFirestore} from "firebase/firestore"
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
    apiKey: "AIzaSyCJxetlPsPBNixmHbpeIGNyvFUA_Lq3vDU",
    authDomain: "toolsmasteronlineweb.firebaseapp.com",
    projectId: "toolsmasteronlineweb",
    storageBucket: "toolsmasteronlineweb.appspot.com",
    messagingSenderId: "39490204312",
    appId: "1:39490204312:web:6375f975b762487cdb56ed",
    measurementId: "G-RQ4E845E83"
};


// Initialize Firebase
const app = initializeApp(firebaseConfig);
export const analytics = getAnalytics(app);
export const db = getFirestore(app);
