import './CSS/Footer.css'
import * as React from "react";
import Logo from "./Logo";
import {Link} from "react-router-dom";
import {Grid} from "@mui/material";

const Footer = () => {
    return (
        <div className="container-fluid footer-container">
            <div style={{width:'100%',margin:'20px'}}>
                <Grid container >
                    <Grid style={{display:'flex',justifyContent:'center'}} item xs={12} sm={12} md={3}>
                        <Logo />
                    </Grid>
                    <Grid item xs={12} sm={12} md={3} style={{display:'flex',justifyContent:'center'}}>
                        <div>
                            <h4>Video Downloader Tools</h4>
                            <ul>
                                <li><Link className={"footer-links"} to={"twitter-downloader"}>Twitter Video Downloader</Link></li>
                                <li><Link className={"footer-links"} to={"instagram-downloader"}>Instagram Video Downloader</Link></li>
                                <li><Link className={"footer-links"} to={"facebook-downloader"}>Facebook Video Downloader</Link></li>
                                <li><Link className={"footer-links"} to={"youtube-downloader"}>Youtube Video Downloader</Link></li>
                            </ul>
                        </div>
                    </Grid>
                    <Grid item xs={12} sm={12} md={3} style={{display:'flex',justifyContent:'center'}}>
                            <h4><Link className={"footer-links"} to={"privacypolicy"}>Our Privacy Policy</Link></h4>
                    </Grid>
                </Grid>
            </div>
        </div>
    )
}

export default Footer;
