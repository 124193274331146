import {Alert, CircularProgress, Link, Snackbar, TextField} from "@mui/material";
import Button from "@mui/material/Button";
import {useState} from "react";
import axios from "axios";
import {Helmet} from "react-helmet-async";


const YoutubeDownloader=()=>{
    const [url, setUrl] = useState('');
    const [loading, setLoading] = useState(false);
    const [links, setLinks] = useState([]);
    const [error, setError] = useState(false);
    const regEx = /^(?:https?:\/\/)?(?:m\.|www\.)?(?:youtu\.be\/|youtube\.com\/(?:embed\/|v\/|watch\?v=|watch\?.+&v=))((\w|-){11})(?:\S+)?$/;
    const download = async () => {
        setLoading(true)
        const options = {
            method: 'GET',
            url: 'https://youtube-media-downloader.p.rapidapi.com/v2/video/details',
            params: {
                videoId: url.match(regEx)[1]
            },
            headers: {
                'X-RapidAPI-Key': 'e4ec10927emsh6bfc7f528f5b208p1f8db3jsn19a2f2c51ead',
                'X-RapidAPI-Host': 'youtube-media-downloader.p.rapidapi.com'
            }
        };
        try {
            const response = await axios.request(options);
            if (response.status===200){
                setLinks(response.data.videos.items);
                setLoading(false);
                setUrl("");
                setError(false);
            }else{
                setLinks([]);
                setUrl("");
                setLoading(false);
                setError(true);
            }

        } catch (error) {
            setLinks([]);
            setUrl("");
            setLoading(false);
            setError(true)
        }
    }

    const handleClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }

        setError(false);
    };
    return(
        <div  className="container">
            <Helmet>
                <html lang="en" />
                <title>Youtube Video Downloader</title>
                <meta name="description" content="Tools Master Youtube video downloader allows you to download Youtube videos from Youtube  for free without any watermark" />
                <meta name="keywords" content="Youtube Video Downloader, Download videos from Youtube, Free video Downloader" />
            </Helmet>
            <div className="header-container">
                <div>
                    <h1>Youtube Video Downloader</h1>
                    <p className="text-center">Download Youtube videos for Free</p>
                </div>
            </div>

            <div className="form-container">
                <TextField value={url} onChange={(e)=>setUrl(e.target.value)} className="text-field" id="url" label="Youtube Video Url" placeholder={"Paste your youtube video Link"} variant="outlined" required/>
                <Button onClick={download} className="download-button" variant={"contained"}>Download</Button>
            </div>

            <div className="download-container">
                <div>
                    {loading?<CircularProgress/>:(links.length>0) &&<div>
                        <h2>Download Links</h2>
                        <div className="links-container">
                            {
                                links.map((link,index)=>{
                                    return(
                                        <div className="download-link-container" key={index}>
                                            <Link className="download-link btn btn-primary"
                                                  style={{textDecoration: 'none',color:'white',width:'100%'}}
                                                  target="_blank"   href={link.url}>
                                                Download in {link.quality}  - {link.extension}</Link>
                                        </div>
                                    )
                                })
                            }
                        </div>
                    </div>}
                </div>

                <Snackbar anchorOrigin={{  vertical: 'top', horizontal: 'center' }} open={error} autoHideDuration={6000} onClose={handleClose}>
                    <Alert onClose={handleClose} severity="error" sx={{ width: '100%' }}>
                        An Error occurred. Please check your Url and try again.
                    </Alert>
                </Snackbar>

            </div>

            <div className={"container"}>
                <h3 className={"text-center"}>Welcome to ToolsMasterOnline.com - Free Youtube Video Downloader</h3>
                <h1>What is Tools Master - Free Youtube Video Downloader</h1>
                <ul>
                    <li>
                        Totally Free and Easy to use: Enjoy the freedom of downloading Youtube videos without any cost.
                        Our user-friendly and easy to use downloader ensures a seamless experience, making video downloads hassle free for users.Also we let users to download the video in multiple formats and multiple resolutions
                    </li>
                    <li>
                        Quick Downloads: Our Youtube Video Downloader provides quick downloads, so you can access your favorite content in no time.
                    </li>
                    <li>
                        Multiple Formats: Our Youtube video downloader allows users to download videos in multiple formats and resultions including low and high resolutions.
                    </li>
                    <li>
                        High-Quality Resolution:Our downloader ensures that the quality of your saved Youtube videos remain high  and also we don't add any watermark to the video.
                    </li>
                </ul>
                <h1>How to Use ToolsMaster Youtube Video Downloader </h1>
                <ol>
                    <li>
                        Copy the Youtube video Link: Find the Youtube video you want to download and copy the link of the video.
                    </li>
                    <li>
                        Paste and Download: Paste the link into our Youtube Video Downloader and hit the download button. You will get some links to download the video in different formats and resolutions
                    </li>
                </ol>
                <h1>
                    Frequently Asked Questions:
                </h1>
                <h5>
                    Q: Is it really free?
                </h5>
                <p>
                    A: Absolutely! ToolsMaster Youtube Video Downloader is completely free to use. No hidden charges or subscriptions.
                </p>
                <h5>
                    Q: Can I download videos from private accounts?
                </h5>
                <p>
                    A: Our downloader supports public videos. For private videos, ensure the Youtube's privacy settings allow for public viewing.
                </p>
                <h5>
                    Q: s it legal to download Youtube videos?
                </h5>
                <p>
                    A: Yes, it's legal to download videos for personal use. However, be sure to respect copyright and intellectual property rights of the original content creator.
                </p>
            </div>
        </div>
    )
}

export default YoutubeDownloader;