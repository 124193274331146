import {Card, CardActions, CardMedia} from "@mui/material";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import {useNavigate} from "react-router-dom";

const CardComponent = (props) => {
    let navigate = useNavigate();
    const handleClick=(path)=>{
        navigate(path);
    }
    return (
        <Card className="m-2" style={{boxShadow: 'rgba(0, 0, 0, 0.25) 0px 14px 28px, rgba(0, 0, 0, 0.22) 0px 10px 10px',margin:'5px'}} sx={{ maxWidth: 345 }}>
            <CardMedia
                sx={{ height: 120, width:120 }}
                image={props.data.image}
                title="green iguana"
                style={{display:'flex',margin:'auto'}}
            />
            <CardActions>
                <Button onClick={()=>handleClick(props.data.path)} size="small" variant="contained">
                    <Typography gutterBottom variant="p" component="div">
                    {props.data.name}
                </Typography></Button>
            </CardActions>
        </Card>
    )
}

export default CardComponent;
