import "./CSS/TwitterDownloader.css";
import {Alert, CircularProgress, Link, Snackbar, TextField} from "@mui/material";
import Button from "@mui/material/Button";
import axios from "axios";
import {useState} from "react";
import {Helmet} from "react-helmet-async";



const TwitterDownloader=()=>{
    const [url, setUrl] = useState('');
    const [loading, setLoading] = useState(false);
    const [links, setLinks] = useState([]);
    const [error, setError] = useState(false);

    const download = async () => {
        setLoading(true)
        let correctedUrl = url.substring(0,8)+"twitter."+url.split(".")[1];
        const options = {
            method: 'POST',
            url: 'https://twitter65.p.rapidapi.com/api/twitter/links',
            headers: {
                'content-type': 'application/json',
                'X-RapidAPI-Key': 'e4ec10927emsh6bfc7f528f5b208p1f8db3jsn19a2f2c51ead',
                'X-RapidAPI-Host': 'twitter65.p.rapidapi.com'
            },
            data: {
                url: correctedUrl
            }
        };

        try {
            const response = await axios.request(options);
            if (response.status===200){
                setLinks(response.data[0].urls);
                setLoading(false);
                setUrl("");
                setError(false);
            }else{
                setLinks([]);
                setUrl("");
                setLoading(false);
                setError(true);
            }

        } catch (error) {
            setLinks([]);
            setUrl("");
            setLoading(false);
            setError(true)
        }
    }

    const handleClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }

        setError(false);
    };

    return(
        <div  className="container">
            <Helmet>
                <html lang="en" />
                <title>Twitter Video Downloader</title>
                <meta name="description" content="Tools Master Twitter video downloader allows you to download Twitter videos from Twitter  for free without any watermark" />
                <meta name="keywords" content="Twitter Video Downloader, Download videos from Twitter, Free video Downloader" />
            </Helmet>
           <div className="header-container">
               <div>
                   <h1>Twitter Video Downloader</h1>
                   <p className="text-center">Download Twitter videos for Free</p>
               </div>
           </div>

            <div className="form-container">
                <TextField value={url} onChange={(e)=>setUrl(e.target.value)} className="text-field" id="url" label="Twitter Video Url" placeholder={"Paste your twitter video Link"} variant="outlined" required/>
                <Button onClick={download} className="download-button" variant={"contained"}>Download</Button>
            </div>

            <div className="download-container">
                <div>
                    {loading?<CircularProgress/>:(links.length>0) &&<div>
                        <h2>Download Links</h2>
                        <div className="links-container">
                            {
                                links.map(link=>{
                                    return(
                                        <div className="download-link-container" key={link.url}>
                                            <Link className="download-link btn btn-primary" style={{textDecoration: 'none',color:'white',width:'100%'}} target="_blank"   href={link.url}>Download in {link.quality} p - {link.extension}</Link>
                                        </div>
                                    )
                                })
                            }
                        </div>
                    </div>}
                </div>

                <Snackbar anchorOrigin={{  vertical: 'top', horizontal: 'center' }} open={error} autoHideDuration={6000} onClose={handleClose}>
                    <Alert onClose={handleClose} severity="error" sx={{ width: '100%' }}>
                        An Error occurred. Please check your Url and try again.
                    </Alert>
                </Snackbar>

            </div>

            <div className={"container"}>
                <h3 className={"text-center"}>Welcome to ToolsMasterOnline.com - Free Twitter Video Downloader</h3>
                <h1>What is Tools Master - Free Twitter Video Downloader</h1>
                <ul>
                    <li>
                        Totally Free and Easy to use: Enjoy the freedom of downloading Twitter videos without any cost.
                        Our user-friendly and easy to use interface ensures a seamless experience, making video downloads hassle free for users.
                    </li>
                    <li>
                        Quick Downloads: Our Twitter Video Downloader provides quick downloads, so you can access your favorite content in no time.
                    </li>
                    <li>
                        Multiple Formats: Our Twitter video downloader allows users to download videos in multiple formats
                    </li>
                    <li>
                        High-Quality Resolution:Our downloader ensures that the quality of your saved Twitter videos remains top-notch and also we don't add any watermark to the video.
                    </li>
                </ul>
                <h1>How to Use ToolsMaster Twitter Video Downloader </h1>
                <ol>
                    <li>
                        Copy the Tweet Link: Find the Twitter video you want to download and copy the link of the video.
                    </li>
                    <li>
                        Paste and Download: Paste the link into our Twitter Video Downloader and hit the download button. You will get some links to download the video in different formats
                    </li>
                </ol>
                <h1>
                    Frequently Asked Questions:
                </h1>
                <h5>
                    Q: Is it really free?
                </h5>
                <p>
                    A: Absolutely! ToolsMaster Twitter Video Downloader is completely free to use. No hidden charges or subscriptions.
                </p>
                <h5>
                    Q: Can I download videos from private accounts?
                </h5>
                <p>
                    A: Our downloader supports public tweets. For private accounts, ensure the tweet's privacy settings allow for public viewing.
                </p>
                <h5>
                    Q: s it legal to download Twitter videos?
                </h5>
                <p>
                    A: Yes, it's legal to download videos for personal use. However, be sure to respect copyright and intellectual property rights of the original content creator.
                </p>
            </div>
        </div>
    )
}

export default TwitterDownloader;